import React from "react";
import "./PinCodeDisplay.css";

interface SquareBoxProps {
  number: number;
}

const SquareBox: React.FC<SquareBoxProps> = ({ number }) => {
  return (
    <div className="square-box">
      <div className="square-box-number">{number}</div>
    </div>
  );
};

interface PinCodeDisplayProps {
  code: number[];
}

const PinCodeDisplay: React.FC<PinCodeDisplayProps> = ({ code }) => {
  return (
    <div className="pin-code-container">
      {[...Array(6)].map((_, index) => (
        <SquareBox number={code[index]} key={index} />
      ))}
    </div>
  );
};

export default PinCodeDisplay;
